import { useMutation } from 'react-query'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { environment } from '../../environments/environment'
import { Season } from '../data'
import { useToast } from '@chakra-ui/react'

export function useCreateExpertCompetition() {
    const { oidcUser } = useReactOidc()
    const toast = useToast()

    return useMutation(
        async ({ season, owner }: { season: Season; owner: string }) => {
            return new Promise((resolve, reject) => {
                fetch(
                    `${environment.apiGatewayUrl}/create-expert-competition`,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${oidcUser.access_token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            season: season.season,
                            sport: season.sport,
                            game: season.game,
                            owner: owner,
                        }),
                    },
                )
                    .then(async (resp) => {
                        if (resp.status !== 200) {
                            const fetchedResp: any = await resp.json()
                            toast({
                                title:
                                    'Oh no, something went wrong creating expert competition..',
                                description:
                                    'Make sure you are registered for the season, if problem persists please check the console and contact the dev team.',
                                status: 'error',
                                duration: 9000,
                                isClosable: true,
                            })
                            reject(
                                fetchedResp?.message
                                    ? fetchedResp.message
                                    : 'Couldnt create expert comp',
                            )
                        }
                        resolve(resp.status)
                    })
                    .catch(() => {
                        reject('Couldnt create expert comp')
                    })
            })
        },
    )
}
