import { Box, Button, Flex, Image, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import TheGameLogo from '../../assets/TheGameLogo'
import { environment } from '../../environments/environment'
import { isLocalEnv } from '../helpers/is-local-env'
import { useGetPresignedUrl } from '../hooks/useGetPresignedUrl'
import { useUploadPublicAssetLocal } from '../hooks/useUploadPublicAssetLocal'
import { FileControl } from './FileControl'
import { MdPublishedWithChanges } from 'react-icons/md'

type ImageUploadProps = {
    fileName: string
}

export function ImageUpload({ fileName }: ImageUploadProps) {
    const [uploadFile] = useUploadPublicAssetLocal()
    const [getSignedUrl] = useGetPresignedUrl()
    const [selectedImage, setSelectedImage] = useState<File | null>(null)
    const [cacheBuster, setCachebuster] = useState(Date.now())
    const toast = useToast()

    const onImageApply = async (file: File | null, filename: string) => {
        if (file === null) return

        const contentType = file.type

        setSelectedImage(file)

        if (isLocalEnv()) {
            await uploadFile({
                file,
                filename,
            })
            setCachebuster(Date.now())
        } else {
            try {
                const response = await getSignedUrl({ filename, contentType })

                if (!response) {
                    throw Error('No response from server')
                }

                const headers = new Headers()
                headers.append('Cache-Control', `public, max-age=120`)

                const result = await fetch(response.url, {
                    method: 'PUT',
                    body: file,
                    headers,
                })

                if (result.status !== 200) {
                    toast({
                        title: 'Oh no something went wrong!',
                        description:
                            'There was an error uploading the file - please check the console and contact the dev team if the problem persists',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                } else {
                    toast({
                        title: 'Success',
                        description: 'Featured Image has been updated',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    setCachebuster(Date.now())
                }
            } catch {
                console.error('Couldnt upload file / configure season')
            }
        }
    }

    return (
        <Box>
            <Flex
                width={450}
                height={120}
                border="1px #65707d77 solid"
                p="8"
                borderRadius="5"
                alignItems="center"
                justifyContent="space-between"
            >
                <Flex>
                    <Image
                        src={
                            selectedImage
                                ? URL.createObjectURL(selectedImage)
                                : isLocalEnv()
                                ? `${environment.apiGatewayUrl}/public-assets/${fileName}?cachebust=${cacheBuster}`
                                : `https://${environment.publicBucketName}.s3.ap-southeast-2.amazonaws.com/public-assets/${fileName}?cachebust=${cacheBuster}`
                        }
                        width={100}
                        height={100}
                        objectFit="contain"
                        border={`1px ${
                            selectedImage ? 'orange' : 'lime'
                        } solid`}
                    />
                </Flex>
                <Flex direction="column">
                    <FileControl
                        buttonLabel="Upload"
                        onSelect={(file) => setSelectedImage(file)}
                        accept=".png"
                    />
                    <Button
                        marginTop={2}
                        colorScheme="blue"
                        onClick={() => onImageApply(selectedImage, fileName)}
                        disabled={selectedImage === null}
                        leftIcon={<MdPublishedWithChanges />}
                    >
                        Apply
                    </Button>
                </Flex>
            </Flex>
        </Box>
    )
}
