import { useQuery } from 'react-query'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { environment } from '../../environments/environment'

export function useGetExpertMappings() {
    const { oidcUser } = useReactOidc()

    return useQuery(['expert-mappings'], async () => {
        const resp = await fetch(
            `${environment.apiGatewayUrl}/expert-mappings`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${oidcUser.access_token}`,
                    'Content-Type': 'application/json',
                },
                cache: 'no-store',
            },
        )
        if (resp.status !== 200) {
            throw new Error('Error retrieving expert mapping')
        }
        return resp.json() as Promise<ExpertMapping[]>
    })
}

export type ExpertMapping = {
    sub: string
    avatar?: string
    title?: string
    fullName: string
}
