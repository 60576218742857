import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import { BlockHeader } from './Shared'

export function Profile({ username }: { username?: string }) {
    return (
        <Box>
            <BlockHeader heading="Profile" indicator={!!username} />

            {username ? (
                <Text>
                    User has created a profile. <br /> Username: {username}
                </Text>
            ) : (
                `That user hasn't filled out a profile.`
            )}
        </Box>
    )
}
