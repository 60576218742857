import { useQuery } from 'react-query'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { environment } from '../../environments/environment'
import { SportName, Round } from '../data'

export function useGetRounds({
    season,
    sport,
}: {
    season: string
    sport: SportName
}) {
    const { oidcUser } = useReactOidc()

    return useQuery(['rounds', season, sport], async () => {
        const resp = await fetch(
            `${environment.apiGatewayUrl}/rounds?sport=${sport}&season=${season}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${oidcUser.access_token}`,
                    'Content-Type': 'application/json',
                },
                cache: 'no-store',
            },
        )
        if (resp.status !== 200) {
            throw new Error('Error retrieving round data')
        }
        return resp.json() as Promise<{ rounds: Round[] }>
    })
}
