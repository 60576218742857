import { Button, Tooltip } from '@chakra-ui/react'
import { format } from 'date-fns'
import React from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

type DatePickerProps = {
    selected: Date | null
    readOnly?: boolean
    onChange: (date: Date) => void
}

export function DatePicker({ selected, onChange, readOnly }: DatePickerProps) {
    return (
        <ReactDatePicker
            selected={selected}
            onChange={onChange}
            readOnly={readOnly}
            dateFormat="yyyy/MM/dd"
            // The custom styling is not working so disable for now
            // customInput={
            //     <Tooltip
            //         label="You cannot modify the date once the round is saved, since this might impact the fixtures"
            //         shouldWrapChildren
            //     >
            //         <Button width="60" disabled={readOnly || false}>
            //             {selected ? format(selected, 'yyyy/MM/dd') : '<select>'}
            //         </Button>
            //     </Tooltip>
            // }
        />
    )
}
