import { Box, Text, Button, Tooltip } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isAfter } from 'date-fns'
import { DatePicker } from '../components/DatePicker'
import { NewRound, SeasonStatus } from '../data'
import { useAddRounds } from '../hooks/useAddRounds'
import { useGetRounds } from '../hooks/useGetRounds'
import { ManageSeasonBaseProps } from './ManageSeason'
import { addDays } from 'date-fns/esm'
import { StyledModal } from '../components/StyledModal'

const ROUND_LENGTH = 7 //days
const parseDate = (input: string) => {
    const d = new Date(input)
    if (d.toString() !== 'Invalid Date') {
        return d
    } else {
        return null
    }
}

export function ConfigureRounds({ season, refetch }: ManageSeasonBaseProps) {
    const [rounds, setRounds] = useState<NewRound[]>([])
    const [showModal, setShowModal] = useState(false)
    const history = useHistory()
    const [addRounds] = useAddRounds()
    const canAddRounds = season.status === SeasonStatus.NotStarted

    const { data: roundData } = useGetRounds({
        sport: season.sport,
        season: season.season,
    })

    useEffect(() => {
        if (roundData && roundData.rounds) {
            const sortedRounds = roundData.rounds.sort(
                (a, b) => a.roundNumber - b.roundNumber,
            )
            const mappedRounds = sortedRounds.map((r) => ({
                start: parseDate(r.start),
                end: parseDate(r.end),
                locked: true,
            }))
            setRounds(mappedRounds)
        }
    }, [roundData])

    const onDone = async () => {
        const response = await addRounds({ rounds, season })
        if (response === 200) {
            setShowModal(false)
            await refetch()
        }
    }

    const isLastRoundLocked = rounds[rounds.length - 1]?.locked

    return (
        <Box display="flex" justifyContent="center">
            <Box width="400px">
                <Text textAlign="center" fontSize={'xx-large'} mt="4" mb="4">
                    Configuring Rounds
                </Text>

                {rounds.map((r, idx) => (
                    <Box key={idx} mb="10">
                        {idx !== 0 && (
                            <Box height="0.5px" flex="1" background="#A8A8A8" />
                        )}
                        <Text mb="2" mt="4">
                            Round: {idx + 1}
                        </Text>
                        <Box ml="10" mr="10" mt="5">
                            <Box display="flex" alignItems="center">
                                <Text mb="2" mt="4" width="20">
                                    Start
                                </Text>
                                <DatePicker
                                    selected={r.start}
                                    onChange={(date) =>
                                        setRounds((allRounds) => {
                                            const round = {
                                                ...allRounds[idx],
                                            }
                                            round.start = date
                                            if (!round.end)
                                                round.end = addDays(
                                                    date,
                                                    ROUND_LENGTH,
                                                )

                                            allRounds[idx] = round
                                            return [...allRounds]
                                        })
                                    }
                                    readOnly={r.locked}
                                />
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Text mb="2" mt="4" width="20">
                                    End
                                </Text>
                                <DatePicker
                                    selected={r.end}
                                    onChange={(date) =>
                                        setRounds((allRounds) => {
                                            const round = {
                                                ...allRounds[idx],
                                            }
                                            round.end = date
                                            allRounds[idx] = round
                                            return [...allRounds]
                                        })
                                    }
                                    readOnly={r.locked}
                                />
                            </Box>
                        </Box>
                    </Box>
                ))}

                <Box display="flex" alignItems="center">
                    <Box height="0.5px" flex="1" background="#A8A8A8" />
                    <Button
                        variant="outline"
                        mr="2"
                        ml="2"
                        disabled={!canAddRounds}
                        onClick={() =>
                            setRounds((r) => {
                                const previousEndDate = r[r.length - 1]?.end
                                return [
                                    ...r,
                                    {
                                        start: previousEndDate,
                                        end:
                                            previousEndDate &&
                                            addDays(
                                                previousEndDate,
                                                ROUND_LENGTH,
                                            ),
                                    },
                                ]
                            })
                        }
                    >
                        Add Round
                    </Button>
                    <Tooltip
                        isDisabled={!isLastRoundLocked}
                        shouldWrapChildren
                        label="You cannot remove a round once it's saved, since this may affect the fixtures"
                    >
                        <Button
                            variant="outline"
                            mr="2"
                            disabled={isLastRoundLocked}
                            onClick={() => {
                                if (isLastRoundLocked) return
                                setRounds((r) => [...r.slice(0, -1)])
                            }}
                        >
                            Remove Round
                        </Button>
                    </Tooltip>
                    <Box height="0.5px" flex="1" background="#A8A8A8" />
                </Box>

                <Box justifyContent="right" display="flex" mb="10" mt="5">
                    <Button
                        variant="outline"
                        onClick={() => history.goBack()}
                        colorScheme="blue"
                        mr="2"
                    >
                        Cancel
                    </Button>
                    <Button
                        colorScheme="blue"
                        onClick={() => setShowModal(true)}
                        disabled={!roundsValid(rounds)}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
            <StyledModal
                size="lg"
                onClose={() => setShowModal(false)}
                isOpen={showModal}
                footer={
                    <>
                        <Button
                            onClick={() => setShowModal(false)}
                            variant="outline"
                            colorScheme="blue"
                        >
                            Cancel
                        </Button>
                        <Button onClick={onDone} colorScheme="blue" ml="4">
                            Confirm
                        </Button>
                    </>
                }
            >
                <Box>
                    <Text>Are you sure you want to save these rounds?</Text>
                    <Text mt="8">
                        Once saved you will not be able to remove them or change
                        the dates since it may affect the fixtures. You can
                        still add more rounds.
                    </Text>
                </Box>
            </StyledModal>
        </Box>
    )
}

const roundsValid = (roundsToSave: NewRound[]) => {
    let valid = true
    roundsToSave.forEach((r, idx) => {
        const roundValid = r.start && r.end && isAfter(r.end, r.start)
        const isAfterPrevious =
            idx > 0 &&
            r.start &&
            isAfter(roundsToSave[idx - 1].end || new Date(), r.start)
        if (!roundValid || isAfterPrevious) valid = false
    })

    return valid
}
