import Resizer from 'react-image-file-resizer'

interface ResizeImageOptions {
    maxWidth?: number
    maxHeight?: number
    quality?: number
    rotation?: number
}

export async function resizeImage(
    originalFile: File,
    {
        maxWidth = 350,
        maxHeight = 350,
        quality = 100,
        rotation = 0,
    }: ResizeImageOptions,
): Promise<File> {
    return new Promise((resolve) => {
        Resizer.imageFileResizer(
            originalFile,
            maxWidth,
            maxHeight,
            'JPEG',
            quality,
            rotation,
            (value) => {
                resolve(
                    new File([value as Blob], 'file.png', {
                        type: 'image/png',
                    }),
                )
            },
            'blob',
        )
    })
}
