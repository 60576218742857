import { useMutation } from 'react-query'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { environment } from '../../environments/environment'
import { Season } from '../data'
import { useToast } from '@chakra-ui/react'

export function useLockSeason() {
    const { oidcUser } = useReactOidc()
    const toast = useToast()

    return useMutation(
        async ({ season, reason }: { season: Season; reason: string }) => {
            return new Promise((resolve, reject) => {
                fetch(`${environment.apiGatewayUrl}/lock-season`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${oidcUser.access_token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        season: season.season,
                        sport: season.sport,
                        game: season.game,
                        reason: reason,
                    }),
                })
                    .then(async (resp) => {
                        if (resp.status !== 200) {
                            const fetchedResp: any = await resp.json()
                            toast({
                                title: `Oh no, something went wrong ${
                                    !season.locked ? 'locking' : 'unlocking'
                                } the season`,
                                description:
                                    'Please check the console and contact the dev team. You might need to use the coach or manually lock the season in the database.',
                                status: 'error',
                                duration: 9000,
                                isClosable: true,
                            })
                            reject(
                                fetchedResp?.message
                                    ? fetchedResp.message
                                    : 'Couldnt lock season',
                            )
                        } else {
                            toast({
                                title: 'Success',
                                description: `Season ${
                                    !season.locked ? 'locked' : 'unlocked'
                                }`,
                                status: 'success',
                                duration: 9000,
                                isClosable: true,
                            })
                        }
                        resolve(resp.status)
                    })
                    .catch(() => {
                        reject('Couldnt publish season')
                    })
            })
        },
    )
}
