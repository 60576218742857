import { Box, ListItem, UnorderedList } from '@chakra-ui/react'
import React from 'react'
import { AdminErrorBoundary } from '../../ErrorBoundary'

function formatValue(value: unknown) {
    if (typeof value === 'object') {
        if (Array.isArray(value)) {
            return (
                <Box>
                    {value.map((item, i) => (
                        <Box key={i}>
                            {i + 1}: {formatValue(item)}
                        </Box>
                    ))}
                </Box>
            )
        } else if (value === null) {
            return 'null'
        } else {
            return parseObject(value as Record<string, unknown>)
        }
    } else {
        return JSON.stringify(value)
    }
}

function parseObject(object: Record<string, unknown>): JSX.Element {
    return (
        <AdminErrorBoundary>
            <UnorderedList ml="8">
                {Object.entries(object).map(
                    ([key, value]) =>
                        ((Array.isArray(value) && value.length > 0) ||
                            (!Array.isArray(value) && value)) && (
                            <ListItem key={key}>
                                <strong>{key}</strong>: {formatValue(value)}
                            </ListItem>
                        ),
                )}
            </UnorderedList>
        </AdminErrorBoundary>
    )
}

export function FormattedEvent({
    event,
}: {
    event: string | { [key: string]: any }
}): JSX.Element {
    return typeof event === 'string' ? (
        <UnorderedList>
            <ListItem>{event}</ListItem>
        </UnorderedList>
    ) : (
        parseObject(event)
    )
}
