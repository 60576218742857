import React from 'react'
import { Box, Divider, Text } from '@chakra-ui/react'
import { CompetitionDTO } from '../../../data'
import { BlockHeader } from '../Shared'
import { CompetitionItem } from '../Competitions/CompetitionItem'

export function Competitions({
    competitions,
}: {
    competitions?: CompetitionDTO[]
}) {
    return (
        <Box>
            <BlockHeader
                heading="Competitions"
                indicator={!!(competitions.length > 0)}
            />
            {competitions?.length > 0 ? (
                <Text>User is part of competitions.</Text>
            ) : (
                <Text>That user hasn't joined/created any competitions.</Text>
            )}
            <Divider
                borderColor="black"
                mb="4"
                mt="4"
                flexBasis="100%"
                flexShrink={0}
            />
            {competitions?.length > 0 &&
                competitions.map((c) => {
                    return (
                        <CompetitionItem
                            key={c.pk}
                            comp={c}
                            lockoutRound={c.lockoutRound}
                        />
                    )
                })}
        </Box>
    )
}
