import {
    Box,
    Heading,
    Radio,
    RadioGroup,
    Stack,
    Table,
    Tbody,
    Text,
    Td,
    Tr,
    Spinner,
    Input,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import {
    MarginDTO,
    MatchDTO,
    usePostUserTips,
    UserTeamToWin,
} from '../../../data'
import { ActionButton, Check, Cross } from '../Shared'
import { INotification, Notification } from '../../components/Notification'

export function EditTips({
    matches,
    subject,
    sport,
    season,
    roundNumber,
    cancelEditing,
    savedMargin,
}: {
    matches: MatchDTO[]
    subject: string
    sport: string
    season: string
    roundNumber: number
    cancelEditing: Function
    savedMargin?: MarginDTO
}) {
    const [tips, setTips] = useState({})
    const [margin, setMargin] = useState(savedMargin?.tippedMargin || 0)
    const [notification, setNotification] = useState({
        message: '',
        type: 'warning',
        hideTimeout: 5000,
    } as INotification)

    function handleTipChange(value: UserTeamToWin, match: MatchDTO) {
        if (tips[match.matchId]) {
            setTips({ ...tips, [match.matchId]: value })
        } else {
            const updatedTips = Object.assign({}, tips)
            updatedTips[match.matchId] = value
            setTips(updatedTips)
        }
    }

    const [
        saveTips,
        { data, isLoading, isError, error, reset },
    ] = usePostUserTips({
        subject,
        sport,
        season,
        roundNumber,
        tips,
        margin,
    })

    if (data && !isError) {
        setNotification({
            type: 'success',
            message: 'Tips have been updated successfully.',
            hideTimeout: 5000,
        })
        reset()
    } else if (isError) {
        const errorBody = error as {
            error: string
            failed?: string[]
        }
        if (errorBody?.failed?.length > 0) {
            const failedTips: string[] = []
            for (const failedId of errorBody?.failed) {
                const failedMatch = matches.find(
                    (match) => match.matchId === failedId,
                )
                failedTips.push(
                    `${failedMatch.homeTeam} vs ${failedMatch.awayTeam} @ ${failedMatch.venue}`,
                )
            }
            setNotification({
                type: 'error',
                message: `Failed to update tips for the following matches: ${failedTips.join(
                    ' & ',
                )}`,
                hideTimeout: 30000,
            })
        } else {
            setNotification({
                type: 'error',
                message: errorBody.error,
                hideTimeout: 5000,
            })
        }
        reset()
    }

    return (
        <Box
            bgColor="#FFFCF0"
            borderRadius="8px"
            border="1px solid #000000"
            padding="1rem"
        >
            <Heading as="h4" size="md" fontWeight="400" textAlign="center">
                Editing Round {roundNumber} tips
            </Heading>
            {matches.map((match, i) => {
                return (
                    <Box key={match.matchId} mt={2} mb={2} fontSize={14}>
                        <Table size="sm" variant="unstyled">
                            <Tbody>
                                <Tr>
                                    <Td
                                        width="5rem"
                                        paddingEnd="0"
                                        paddingStart="0"
                                    >
                                        Match {1 + i++}:
                                        {match.isFeatured && (
                                            <Text fontWeight="600">
                                                Featured
                                            </Text>
                                        )}
                                    </Td>
                                    <Td paddingEnd="0" paddingStart="0">
                                        {`${
                                            match.homeTeam
                                                ? match.homeTeam
                                                : 'TBC'
                                        } vs ${
                                            match.awayTeam
                                                ? match.awayTeam
                                                : 'TBC'
                                        } @ ${
                                            match.venue ? match.venue : 'TBC'
                                        }`}
                                    </Td>
                                    <Td
                                        textAlign="end"
                                        paddingEnd="0"
                                        fontWeight="600"
                                    >
                                        {match.selectedTip}
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                        <Box ml="5rem">
                            <RadioGroup
                                id={match.matchId}
                                defaultValue={match.selectedTip}
                                onChange={(value) =>
                                    handleTipChange(value, match)
                                }
                                isDisabled={
                                    (match.status !== 'scheduled' &&
                                        match.status !== 'pre-match') ||
                                    match.isLocked
                                }
                            >
                                <Stack direction="row" spacing={5}>
                                    <Radio
                                        id={match.matchId}
                                        value="HOME"
                                        cursor="pointer"
                                    >
                                        <Text cursor="pointer" color="#001AFF">
                                            HOME
                                        </Text>
                                    </Radio>
                                    <Radio
                                        id={match.matchId}
                                        value="AWAY"
                                        cursor="pointer"
                                    >
                                        <Text cursor="pointer" color="#001AFF">
                                            AWAY
                                        </Text>
                                    </Radio>
                                    <Radio
                                        id={match.matchId}
                                        value="NONE"
                                        cursor="pointer"
                                    >
                                        <Text cursor="pointer" color="#001AFF">
                                            NONE
                                        </Text>
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                        </Box>
                        {match.isFeatured && (
                            <Box ml="5rem" mt="0.5rem">
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Text mr="1rem">Margin: </Text>
                                    <Input
                                        backgroundColor="white"
                                        type="numeric"
                                        value={margin}
                                        onChange={({
                                            currentTarget: { value },
                                        }) => {
                                            if (!isNaN(Number(value)))
                                                setMargin(Number(value))
                                        }}
                                    />
                                </div>
                            </Box>
                        )}
                    </Box>
                )
            })}
            <Box display="flex" justifyContent="space-between">
                <ActionButton onClick={() => cancelEditing()}>
                    Cancel {Cross()}
                </ActionButton>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <ActionButton
                        onClick={() => {
                            Object.keys(tips).length === 0 &&
                            savedMargin?.tippedMargin === margin
                                ? setNotification({
                                      type: 'warning',
                                      message:
                                          'Please select the tips you would like to update first.',
                                      hideTimeout: 5000,
                                  })
                                : saveTips()
                        }}
                    >
                        Save {Check()}
                    </ActionButton>
                )}
            </Box>
            {notification.message && notification.type && (
                <Notification
                    type={notification.type}
                    message={notification.message}
                    hideTimeout={notification.hideTimeout}
                    onNotificationClose={() =>
                        setNotification({
                            type: 'warning',
                            message: '',
                            hideTimeout: 5000,
                        })
                    }
                />
            )}
        </Box>
    )
}
