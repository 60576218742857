import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Text,
} from '@chakra-ui/react'
import React from 'react'
import { AvailableGameDTO, GameRegistrationDTO, MarginDTO } from '../../../data'
import { Matches } from '../Matches/Matches'
import { ActionButton, BlockHeader, Pen } from '../Shared'
import { EditTips } from './EditTips'

export function Tips({
    margins,
    registrations,
    availableGames,
    subject,
}: {
    margins: MarginDTO[]
    registrations: GameRegistrationDTO[]
    availableGames: AvailableGameDTO[]
    subject: string
}) {
    const [editTips, setEditTips] = React.useState(-1)

    return (
        <Box>
            <BlockHeader heading="Tips" />

            {availableGames.map((game) => {
                const registration = registrations?.find(
                    (x) =>
                        x.sport === game.sport &&
                        x.season === game.season &&
                        x.game === game.game,
                )
                return (
                    registration && (
                        <Accordion
                            allowMultiple
                            key={`${game.sport}-${game.season}-${game.game}`}
                        >
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            {game.sport} {game.season}{' '}
                                            {game.game}
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    {Object.keys(game.rounds).map(
                                        (roundNumber) => {
                                            const parsedRoundNumber = parseInt(
                                                roundNumber,
                                            )
                                            return (
                                                <Box key={parsedRoundNumber}>
                                                    <Accordion
                                                        allowMultiple
                                                        key={parsedRoundNumber}
                                                    >
                                                        <AccordionItem>
                                                            <h2>
                                                                <AccordionButton>
                                                                    <Box
                                                                        flex="1"
                                                                        textAlign="left"
                                                                    >
                                                                        <Text>
                                                                            Round{' '}
                                                                            {
                                                                                parsedRoundNumber
                                                                            }
                                                                        </Text>
                                                                    </Box>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                            </h2>
                                                            <AccordionPanel
                                                                pb={4}
                                                            >
                                                                {parsedRoundNumber >=
                                                                    game.currentRound &&
                                                                    editTips !==
                                                                        parsedRoundNumber && (
                                                                        <ActionButton
                                                                            onClick={() => {
                                                                                setEditTips(
                                                                                    parsedRoundNumber,
                                                                                )
                                                                            }}
                                                                        >
                                                                            Edit
                                                                            tips{' '}
                                                                            {Pen()}
                                                                        </ActionButton>
                                                                    )}
                                                                {editTips ===
                                                                parsedRoundNumber ? (
                                                                    <Box>
                                                                        <EditTips
                                                                            savedMargin={margins.find(
                                                                                (
                                                                                    m,
                                                                                ) =>
                                                                                    m.sport ===
                                                                                        game.sport &&
                                                                                    m.season ===
                                                                                        game.season &&
                                                                                    m.roundNumber ===
                                                                                        parsedRoundNumber,
                                                                            )}
                                                                            matches={
                                                                                game
                                                                                    .rounds[
                                                                                    parsedRoundNumber
                                                                                ]
                                                                            }
                                                                            subject={
                                                                                subject
                                                                            }
                                                                            sport={
                                                                                game.sport
                                                                            }
                                                                            season={
                                                                                game.season
                                                                            }
                                                                            roundNumber={
                                                                                parsedRoundNumber
                                                                            }
                                                                            cancelEditing={() => {
                                                                                setEditTips(
                                                                                    -1,
                                                                                )
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                ) : (
                                                                    <Matches
                                                                        matches={
                                                                            game
                                                                                .rounds[
                                                                                parsedRoundNumber
                                                                            ]
                                                                        }
                                                                    />
                                                                )}
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    </Accordion>
                                                </Box>
                                            )
                                        },
                                    )}
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    )
                )
            })}
        </Box>
    )
}
