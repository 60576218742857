import {
    Box,
    Text,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Input,
    Select,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SportName, useCreateSeason } from '../data'

type Season = {
    sport: string
    season: string
    sendGridId?: string
    ingestionCompId: string
    oddsId: string
    launchType: string
}

const emptySeason: Season = {
    sport: '',
    season: '',
    ingestionCompId: '',
    oddsId: '',
    launchType: 'Full Launch',
}

export function NewSeasonForm() {
    const history = useHistory()
    const [newSeason, setNewSeason] = useState<Season>(emptySeason)
    const [createSeason] = useCreateSeason()

    const onChange = (
        prop: 'sport' | 'season' | 'ingestionCompId' | 'oddsId' | 'launchType',
        value: string,
    ) => {
        setNewSeason((originalSeason) => {
            if (prop === 'sport') {
                originalSeason[prop] = value as SportName
                originalSeason['season'] = ''
            } else {
                originalSeason[prop] = value
            }
            return { ...originalSeason }
        })
    }

    const validSeason = (checkSeason: Season) => {
        return (
            checkSeason.sport !== '' &&
            checkSeason.season !== '' &&
            checkSeason.ingestionCompId !== '' &&
            checkSeason.launchType !== ''
        )
    }

    const onCreateSeasonPress = async () => {
        if (validSeason(newSeason)) {
            const body = JSON.stringify(newSeason)
            await createSeason(body)
            history.goBack()
        } else {
            console.log('invalid season')
        }
    }

    const getDates = (sport: string) => {
        const thisYear = new Date().getFullYear()
        if (sport === 'Cricket') {
            return [
                `${thisYear}-${(thisYear + 1).toString().slice(2)}`,
                `${thisYear + 1}-${(thisYear + 2).toString().slice(2)}`,
            ]
        }
        return [`${thisYear}`, `${thisYear + 1}`]
    }

    return (
        <Box px="10">
            <Box>
                <Breadcrumb
                    spacing="8px"
                    separator={<ChevronRightIcon color="gray.500" />}
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/seasons">
                            Manage Seasons
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink href={`/seasons/newseason`}>
                            Create New Season
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </Box>

            <Box display="flex" justifyContent="center">
                <Box width="400px">
                    <Text textAlign="center" fontSize={'xx-large'} mt="4">
                        Create New Season
                    </Text>

                    <Box ml="10" mr="10" mt="5">
                        <Text mb="2" mt="4">
                            Sport (Required)
                        </Text>
                        <Select
                            placeholder="Select sport"
                            onChange={(e) => onChange('sport', e.target.value)}
                            value={newSeason.sport}
                        >
                            <option value="AFL">AFL</option>
                            <option value="AFLW">AFLW</option>
                            <option value="WAFL">WAFL</option>
                            <option value="Cricket">Cricket</option>
                        </Select>

                        <Text mb="2" mt="4">
                            Season (Required)
                        </Text>
                        <Select
                            placeholder="Select season"
                            onChange={(e) => onChange('season', e.target.value)}
                            value={newSeason.season}
                        >
                            {getDates(newSeason.sport).map((date, index) => (
                                <option key={index} value={date}>
                                    {date}
                                </option>
                            ))}
                        </Select>
                        <Box>
                            <Text mb="2" mt="4">
                                {newSeason.sport !== 'Cricket' &&
                                newSeason.sport !== 'AFLW'
                                    ? 'ChampionData'
                                    : 'StatsPerform'}{' '}
                                Comp ID (Required)
                            </Text>
                            <Input
                                title="Ingrestion Comp Id"
                                value={newSeason.ingestionCompId}
                                onChange={(e) =>
                                    onChange('ingestionCompId', e.target.value)
                                }
                            />
                        </Box>

                        <Text mb="2" mt="4">
                            Odds ID (Optional)
                        </Text>
                        <Input
                            title="Odds ID"
                            value={newSeason.oddsId}
                            onChange={(e) => onChange('oddsId', e.target.value)}
                        />

                        <Text mb="2" mt="4">
                            Launch Type (Required)
                        </Text>
                        <Select
                            // placeholder="Select launch type"
                            onChange={(e) =>
                                onChange('launchType', e.target.value)
                            }
                            value={newSeason?.launchType}
                        >
                            <option value="Soft Launch">Soft Launch</option>
                            <option value="Full Launch">Full Launch</option>
                        </Select>
                    </Box>

                    <Box
                        justifyContent="right"
                        display="flex"
                        mr="10"
                        mb="10"
                        mt="5"
                    >
                        <Button
                            variant="outline"
                            onClick={() => history.goBack()}
                            colorScheme="blue"
                            mr="2"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => onCreateSeasonPress()}
                            colorScheme="blue"
                            disabled={!validSeason(newSeason)}
                        >
                            Create season
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
