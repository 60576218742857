import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
} from '@chakra-ui/react'
import React from 'react'
import {
    AvailableGameDTO,
    GameRegistrationDTO,
    MarginDTO,
    useGetMatchInfo,
} from '../../../data'
import { BlockHeader } from '../Shared'
import { Margin } from './Margin'

export function Margins({
    margins,
    registrations,
    availableGames,
}: {
    margins: MarginDTO[]
    registrations: GameRegistrationDTO[]
    availableGames: AvailableGameDTO[]
}) {
    return (
        <Box>
            <BlockHeader heading="Margins" />
            {availableGames.map((game) => {
                const registration = registrations?.find(
                    (x) =>
                        x.sport === game.sport &&
                        x.season === game.season &&
                        x.game === game.game,
                )
                return (
                    registration && (
                        <Accordion
                            allowMultiple
                            key={`${game.sport}-${game.season}-${game.game}`}
                        >
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            {game.sport} {game.season}{' '}
                                            {game.game}
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <MatchDetails
                                        registration={registration}
                                        margins={margins}
                                    />
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    )
                )
            })}
        </Box>
    )
}

function MatchDetails({
    registration,
    margins,
}: {
    registration: GameRegistrationDTO
    margins: MarginDTO[]
}) {
    const { data: matchInfo } = useGetMatchInfo(
        registration.sport,
        registration.season,
    )
    const filteredMargins = margins.filter(
        (margin) =>
            margin.sport === registration.sport &&
            margin.season === registration.season,
    )
    return (
        <Margin
            filteredMargins={filteredMargins}
            matches={matchInfo?.matches}
        />
    )
}
