import { Box, Grid, GridItem } from '@chakra-ui/react'
import React from 'react'
import { UserByEmailDTO } from '../data'
import { Account } from './components/Account'
import { AuditLogs } from './components/AuditLogs'
import { Competitions } from './components/Competitions/Competitions'
import { CompetitionsSearch } from './components/Competitions/CompetitionsSearch'
import { Margins } from './components/Margins/Margins'
import { Profile } from './components/Profile'
import { Tips } from './components/Tips/Tips'

export function UserResult({ data }: { data: UserByEmailDTO }) {
    const {
        subject,
        username,
        registrations,
        auditLogs,
        margins,
        email,
        competitions,
        availableGames,
    } = data

    return (
        <Box>
            <Grid templateColumns="repeat(4, 1fr)" gap="10">
                <GridItem>
                    <Box>
                        <Account subject={subject} email={email} />
                    </Box>
                    <Box mt="10">
                        <Profile username={username} />
                    </Box>
                    <Box mt="10">
                        <Competitions competitions={competitions} />
                    </Box>
                    <Box mt="10">
                        <AuditLogs auditLogs={auditLogs} />
                    </Box>
                </GridItem>
                <GridItem>
                    <Tips
                        margins={margins}
                        registrations={registrations}
                        availableGames={availableGames}
                        subject={subject}
                    />
                </GridItem>
                <GridItem>
                    <Margins
                        margins={margins}
                        registrations={registrations}
                        availableGames={availableGames}
                    />
                </GridItem>
                <GridItem>
                    <CompetitionsSearch subject={subject} />
                </GridItem>
            </Grid>
        </Box>
    )
}

export default UserResult
