import { Box, Flex, Text, Input, Button, Textarea } from '@chakra-ui/react'

import { Subtle } from './SponsorWidget'
import { MdCancel, MdEdit, MdUpload } from 'react-icons/md'
import React, { useState } from 'react'

interface Version {
    version: string
    header: string
    body: string
}

export function VersionWidget({
    versionProps,
    name,
    loading,
    handleUpdateAppVersion,
}: {
    versionProps: Version
    name: string
    loading: boolean
    handleUpdateAppVersion: (version: Version & { name: string }) => void
}) {
    const { version, header, body } = versionProps

    const [editable, setEditable] = useState(false)

    const [newVersion, setNewVersion] = useState(version || '')

    const [newHeader, setNewHeader] = useState(header || '')
    const [newBody, setNewBody] = useState(body || '')

    function handleClickEdit() {
        setNewVersion(version || '')
        setNewHeader(header || '')
        setNewBody(body || '')
        setEditable(!editable)
    }

    async function handleClickUpdate() {
        await handleUpdateAppVersion({
            name: name,
            version: newVersion,
            header: newHeader,
            body: newBody,
        })

        setEditable(false)
    }

    return (
        <Flex
            direction="column"
            padding={4}
            border="1px #65707d77 solid"
            borderRadius={8}
            width={480}
            minHeight={120}
            opacity={loading ? '0.6' : '1'}
        >
            <Flex direction={'column'} gap={2}>
                <div>
                    <Subtle>Name </Subtle>
                    <Text
                        fontSize={'large'}
                        fontWeight="bold"
                        textTransform={'capitalize'}
                    >
                        {name}
                    </Text>
                </div>
                <br />

                <Subtle>Version Number: </Subtle>
                {editable ? (
                    <Input
                        placeholder={version}
                        size="sm"
                        value={newVersion}
                        onChange={(e) => setNewVersion(e.target.value)}
                    />
                ) : (
                    <Text fontSize={'large'} fontWeight="bold">
                        {version}
                    </Text>
                )}

                <Subtle>Message Header:</Subtle>
                {editable ? (
                    <Input
                        placeholder={header}
                        size="sm"
                        value={newHeader}
                        onChange={(e) => setNewHeader(e.target.value)}
                    />
                ) : (
                    <Text fontSize={'md'}>{header}</Text>
                )}
                <Subtle>Message body:</Subtle>
                {editable ? (
                    <Textarea
                        placeholder={body}
                        size="sm"
                        value={newBody}
                        onChange={(e) => setNewBody(e.target.value)}
                    />
                ) : (
                    <Text fontSize={'md'}>{body}</Text>
                )}
                <Box marginTop={4}>
                    <Flex justifyContent={'space-between'}>
                        <Button
                            onClick={() => handleClickEdit()}
                            leftIcon={editable ? <MdCancel /> : <MdEdit />}
                            disabled={loading}
                        >
                            {editable ? 'Cancel' : 'Edit'}
                        </Button>

                        {editable && (
                            <Button
                                onClick={() => handleClickUpdate()}
                                colorScheme="blue"
                                leftIcon={<MdUpload />}
                                disabled={loading}
                            >
                                Update
                            </Button>
                        )}
                    </Flex>
                </Box>
            </Flex>
        </Flex>
    )
}
