import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

export function AdminErrorBoundary({
    children,
}: {
    children: React.ReactNode
}) {
    return (
        <ErrorBoundary
            FallbackComponent={({ error }) => (
                <>
                    <p>Something went wrong :(</p>
                    <p>
                        Please post the below error in the Slack channel, and
                        we'll try to fix it up.
                    </p>
                    <p>In the mean time, try refreshing the page.</p>
                    <br />
                    <details>
                        <summary>View Error</summary>
                        <code>{JSON.stringify(error)}</code>
                    </details>
                </>
            )}
        >
            {children}
        </ErrorBoundary>
    )
}
