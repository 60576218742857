import React, { ChangeEvent, useRef } from 'react'
import { Button } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'

type FileUploadProps = {
    buttonLabel: string
    accept?: string
    variant?: string
    onSelect: (file: File) => void
}

const SUPPORTED_FORMATS = ['.jpg', '.png', '.pdf']

export const FileControl: React.FC<FileUploadProps> = React.forwardRef(
    (props: FileUploadProps) => {
        const {
            accept = SUPPORTED_FORMATS.join(' ,'),
            onSelect,
            buttonLabel,
            variant = 'solid',
        } = props

        const inputRef = useRef<HTMLInputElement | null>(null)

        const onChange = (value: ChangeEvent<HTMLInputElement>) => {
            value.target.files &&
                value.target.files.length > 0 &&
                onSelect(value.target.files?.[0])
        }

        return (
            <>
                <input
                    onChange={onChange}
                    type="file"
                    accept={accept}
                    multiple={false}
                    ref={inputRef}
                    hidden
                />
                <Button
                    colorScheme="blue"
                    variant={variant}
                    leftIcon={<AddIcon />}
                    onClick={() => inputRef.current?.click()}
                >
                    {buttonLabel}
                </Button>
            </>
        )
    },
)
