import { useMutation } from 'react-query'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { environment } from '../../environments/environment'

type Response = {
    url: string
}

export function useGetPresignedUrl() {
    const { oidcUser } = useReactOidc()

    return useMutation(
        ({
            filename,
            contentType,
            method = 'GET',
        }: {
            filename: string
            contentType: string
            method?: 'GET' | 'DELETE'
        }) => {
            return new Promise<Response>((resolve, reject) => {
                fetch(
                    `${environment.apiGatewayUrl}/presigned-url?filename=${filename}&contentType=${contentType}&method=${method}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${oidcUser.access_token}`,
                            'Content-Type': 'application/json',
                        },
                    },
                )
                    .then(async (resp) => {
                        if (resp.status !== 200) {
                            const fetchedResp: any = await resp.json()
                            reject(
                                fetchedResp?.message
                                    ? fetchedResp.message
                                    : 'Couldnt get presigned URL',
                            )
                        }
                        resolve(await resp.json())
                    })
                    .catch(() => {
                        reject('Couldnt get presigned URL')
                    })
            })
        },
    )
}
