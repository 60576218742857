import { useMutation } from 'react-query'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { environment } from '../../environments/environment'
import { useToast } from '@chakra-ui/react'

export function useUpdateExpertMapping() {
    const { oidcUser } = useReactOidc()
    const toast = useToast()

    return useMutation(
        async ({
            userSub,
            title,
            avatar,
            name,
        }: {
            userSub: string
            title: string
            avatar: string
            name: string
        }) => {
            return new Promise((resolve, reject) => {
                fetch(`${environment.apiGatewayUrl}/expert-mapping`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${oidcUser.access_token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userSub,
                        title,
                        avatar,
                        name,
                    }),
                })
                    .then(async (resp) => {
                        if (resp.status !== 200) {
                            const fetchedResp: any = await resp.json()
                            toast({
                                title:
                                    'Oh no, something went wrong updating experts..',
                                description:
                                    'Please check the console and contact the dev team.',
                                status: 'error',
                                duration: 9000,
                                isClosable: true,
                            })
                            reject(
                                fetchedResp?.message
                                    ? fetchedResp.message
                                    : 'Couldnt update expert mapping',
                            )
                        }
                        resolve(resp.status)
                    })
                    .catch(() => {
                        reject('Couldnt update expert mapping')
                    })
            })
        },
    )
}
