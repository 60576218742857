import React from 'react'
import { Button, Divider, Flex, Text } from '@chakra-ui/react'

export function BlockHeader({
    heading,
    indicator,
}: React.PropsWithoutRef<{ heading: string; indicator?: boolean }>) {
    return (
        <Flex
            as={'h4'}
            fontWeight="normal"
            fontSize="x-large"
            justifyContent="space-between"
            flexWrap="wrap"
        >
            {heading}
            {indicator !== undefined && <Indicator success={indicator} />}
            <Divider
                borderColor="black"
                mb="4"
                mt="2"
                flexBasis="100%"
                flexShrink={0}
            />
        </Flex>
    )
}

export function Check() {
    return (
        <span role="img" aria-label="Check">
            ✅
        </span>
    )
}

export function Cross() {
    return (
        <span role="img" aria-label="Cross">
            ❌
        </span>
    )
}

export function Pen() {
    return (
        <span role="img" aria-label="Pencil">
            &#9998;
        </span>
    )
}

export function ActionButton(props) {
    return (
        <Button
            onClick={props.onClick}
            color="#001AFF"
            variant="link"
            borderRadius="0"
            fontWeight="400"
        >
            <Text lineHeight={1} textDecoration="underline">
                {props.children}
            </Text>
        </Button>
    )
}

export function Indicator({ success }: { success: boolean }) {
    return success ? <Check /> : <Cross />
}
