import React from 'react'
import { Box, Text, Button } from '@chakra-ui/react'
import { CheckCircleIcon, WarningIcon, AddIcon } from '@chakra-ui/icons'
import { Season, Round, SeasonStatus } from '../data'
import { useHistory } from 'react-router-dom'
import { CardComponent } from '../components/CardComponent'

export function AddRoundsSection({
    season,
    rounds,
}: {
    season: Season
    rounds: Round[] | undefined
}) {
    const history = useHistory()

    if (season.sport !== 'Cricket') return <Box />

    const canAddRounds = season.status === SeasonStatus.NotStarted

    return (
        <Box mt="8">
            <Text fontSize={'x-large'}>Add Rounds</Text>
            <CardComponent
                action={
                    <Button
                        colorScheme="blue"
                        leftIcon={canAddRounds ? <AddIcon /> : undefined}
                        onClick={() =>
                            history.push(
                                `/seasons/${season.sport}_${season.season}/add_rounds`,
                            )
                        }
                    >
                        {canAddRounds ? 'Add Rounds' : 'Show Rounds'}
                    </Button>
                }
                title="Rounds"
                content={
                    rounds && rounds.length > 0 ? (
                        <Box display="flex" flexDir="row" alignItems="center">
                            <CheckCircleIcon color="green" mr="1" />

                            <Text color="#5C5C5C">
                                {rounds.length} Configured
                            </Text>
                        </Box>
                    ) : (
                        <Box display="flex" flexDir="row" alignItems="center">
                            <WarningIcon color="#D22828" mr="1" />

                            <Text color="#5C5C5C">None Configured</Text>
                        </Box>
                    )
                }
            />
        </Box>
    )
}
