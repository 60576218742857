import { useMutation } from 'react-query'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { environment } from '../../environments/environment'
import { Season } from '../data'
import { useToast } from '@chakra-ui/react'

export function usePublishSeason() {
    const { oidcUser } = useReactOidc()
    const toast = useToast()

    return useMutation(async ({ season }: { season: Season }) => {
        return new Promise((resolve, reject) => {
            fetch(`${environment.apiGatewayUrl}/publish-season`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${oidcUser.access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    season: season.season,
                    sport: season.sport,
                    game: season.game,
                    published: season.published,
                    winners: season.winners,
                }),
            })
                .then(async (resp) => {
                    if (resp.status !== 200) {
                        const fetchedResp: any = await resp.json()
                        toast({
                            title:
                                'Oh no, something went wrong publishing season..',
                            description:
                                'Please check the console and contact the dev team.',
                            status: 'error',
                            duration: 9000,
                            isClosable: true,
                        })
                        reject(
                            fetchedResp?.message
                                ? fetchedResp.message
                                : 'Couldnt publish season',
                        )
                    } else {
                        toast({
                            title: 'Success',
                            description: 'Season has been updated',
                            status: 'success',
                            duration: 9000,
                            isClosable: true,
                        })
                    }
                    resolve(resp.status)
                })
                .catch(() => {
                    reject('Couldnt publish season')
                })
        })
    })
}
