import { useMutation } from 'react-query'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { environment } from '../../environments/environment'
import { useToast } from '@chakra-ui/react'

export function usePostAvatar() {
    const { oidcUser } = useReactOidc()
    const toast = useToast()

    return useMutation(async ({ file, name }: { file: File; name: string }) => {
        return new Promise((resolve, reject) => {
            //NOTE: this is to TheGameAPI not AdminAPI
            fetch(`${environment.thegameApiUrl}/avatar?name=${name}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${oidcUser.access_token}`,
                },
                body: file,
            })
                .then(async (resp) => {
                    if (resp.status !== 201) {
                        const fetchedResp: any = await resp.json()

                        toast({
                            title:
                                'Oh no, something went wrong uploading avatar..',
                            description:
                                'Please check the console and contact the dev team. You might need to manually put the file into the pubic_assets/ S3 bucket',
                            status: 'error',
                            duration: 9000,
                            isClosable: true,
                        })
                        reject(
                            fetchedResp?.message
                                ? fetchedResp.message
                                : 'Couldnt update avatar',
                        )
                    }
                    resolve(resp.json())
                })
                .catch(() => {
                    reject('Couldnt update avatar')
                })
        })
    })
}
