import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogOverlay,
    Grid,
    GridItem,
    Text,
    Link,
    useDisclosure,
    Tooltip,
    Button,
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    ModalHeader,
    Heading,
    Spinner,
    Divider,
    Input,
    Flex,
} from '@chakra-ui/react'
import React from 'react'
import {
    CompetitionDTO,
    usePostAddToCompetition,
    useUpdateCompetition,
} from '../../../data'
import { Pen } from '../Shared'

export function CompetitionItem({
    comp,
    subject,
    isAddBtn,
    isRefetching,
    refetchCompetitions,
    lockoutRound,
}: {
    comp: CompetitionDTO
    subject?: string
    isAddBtn?: boolean
    isRefetching?: boolean
    refetchCompetitions?: Function
    lockoutRound?: number
}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [errorMessage, setErrorMessage] = React.useState('')
    const cancelRef = React.useRef()
    const [
        addToCompetition,
        { data, isLoading, isError, error, reset },
    ] = usePostAddToCompetition()

    if (isError) {
        onOpen()
        setErrorMessage(error as string)
        reset()
    } else if (!isError && data) {
        reset()
        refetchCompetitions()
    }

    const url = `https://thegame.com.au/${comp.sport}/${comp.game}/comp/${comp.pk}`

    const roundDeadline = lockoutRound || 0

    return (
        <Grid
            display="grid"
            templateColumns="repeat(2, 1fr)"
            templateRows="repeat(2, 1fr)"
            mb="2"
            gap="1"
            borderBottom="1px solid lightgray"
            pb="4"
        >
            <AlertDialog
                motionPreset="slideInBottom"
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent padding="5">
                    <AlertDialogCloseButton />
                    <AlertDialogBody color="red">
                        {errorMessage}
                    </AlertDialogBody>
                </AlertDialogContent>
            </AlertDialog>
            <GridItem colSpan={4}>
                <Flex justifyContent="space-between" flexWrap="wrap">
                    <Text
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                    >
                        <Tooltip
                            label={
                                <div>
                                    <p>Sport: {comp.sport}</p>
                                    <p>Season: {comp.season}</p>
                                    {roundDeadline > 0 && (
                                        <p>Round Deadline: {roundDeadline}</p>
                                    )}
                                </div>
                            }
                            aria-label="Competition details"
                        >
                            {comp.competitionName}
                        </Tooltip>
                    </Text>
                    <EditComp
                        competitionCode={comp.pk}
                        lockoutRound={roundDeadline}
                    ></EditComp>
                </Flex>
            </GridItem>
            <GridItem>
                <Link
                    target="_blank"
                    color="#001AFF"
                    marginRight="5"
                    href={url}
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                >
                    {comp.pk}
                </Link>
                <Text as="i">
                    {comp.members.length === 1
                        ? comp.members.length + ' member'
                        : comp.members.length + ' members'}
                </Text>
            </GridItem>
            <GridItem textAlign="right">
                {comp.isJoined ? (
                    <Text as="i">
                        {comp.currentUserRank
                            ? `#${comp.currentUserRank} of ${comp.currentUserRankOutOf}`
                            : 'TBC'}
                    </Text>
                ) : (
                    isAddBtn && (
                        <Button
                            isLoading={isLoading || isRefetching}
                            variant="link"
                            fontWeight={500}
                            color="#001AFF"
                            textDecoration="underline"
                            cursor="pointer"
                            onClick={() => {
                                addToCompetition({
                                    competitionId: comp.pk,
                                    subject,
                                })
                            }}
                            role="button"
                        >
                            Add &#x2B;
                        </Button>
                    )
                )}
            </GridItem>
        </Grid>
    )
}

export function EditComp({
    competitionCode,
    lockoutRound,
}: {
    competitionCode: string
    lockoutRound: number
}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [newLockoutRound, setNewLockoutRound] = React.useState('')

    const [
        sendUpdateCompetitionRequest,
        { data, isLoading, isError, reset },
    ] = useUpdateCompetition({
        competitionCode,
        lockoutRound: Number(newLockoutRound),
    })

    function validateRoundLockout(lockoutRound: string) {
        const reg = /^\d{1,2}$/
        return lockoutRound.match(reg)
    }

    function handleClose() {
        setNewLockoutRound('')
        onClose()
        reset()
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between">
                <Text
                    color="#001AFF"
                    textDecoration="underline"
                    cursor="pointer"
                    onClick={() => onOpen()}
                    role="button"
                >
                    Edit {Pen()}
                </Text>
            </Box>

            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton onClick={() => handleClose()} />
                    <ModalBody pt="10">
                        {!isError && !data && (
                            <Box>
                                <ModalHeader padding="0">
                                    <Box>
                                        <Heading>Edit Competition</Heading>
                                        <Text
                                            fontWeight="normal"
                                            fontSize="medium"
                                        >
                                            This allows you to edit a Round
                                            Deadline.
                                        </Text>
                                    </Box>
                                </ModalHeader>
                                <Divider margin="16px 0px" />
                            </Box>
                        )}
                        {!isError && !data && (
                            <Box marginTop={6}>
                                <Text>
                                    Current Round Deadline : {lockoutRound}
                                </Text>
                            </Box>
                        )}
                        {isError ? (
                            <Box>
                                <Heading>Error</Heading>
                                <Text>
                                    An error was encountered and the round
                                    deadline was not changed.
                                </Text>
                                <Text>
                                    Please contact the Digital team for
                                    assistance.
                                </Text>
                            </Box>
                        ) : (
                            !isLoading &&
                            !isError &&
                            data && (
                                <Box>
                                    <Heading>Success</Heading>
                                    <Text>
                                        The round deadline has been changed.
                                    </Text>
                                </Box>
                            )
                        )}
                    </ModalBody>
                    {!isLoading ? (
                        <ModalFooter display="flex" justifyContent="center">
                            {!isError && !data ? (
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    width="100%"
                                >
                                    <Box display="flex">
                                        <Input
                                            name="round deadline"
                                            type="number"
                                            inputMode="numeric"
                                            maxLength={2}
                                            pattern="^d{1,2}$"
                                            placeholder="Enter a round deadline"
                                            value={newLockoutRound}
                                            onChange={(event) =>
                                                setNewLockoutRound(
                                                    event.target.value,
                                                )
                                            }
                                        />
                                        <Button
                                            ml={2}
                                            colorScheme="gray"
                                            onClick={() =>
                                                sendUpdateCompetitionRequest()
                                            }
                                            disabled={
                                                !validateRoundLockout(
                                                    newLockoutRound,
                                                )
                                            }
                                        >
                                            Update
                                        </Button>
                                    </Box>
                                    <Button
                                        variant="ghost"
                                        onClick={() => handleClose()}
                                    >
                                        <span>Cancel</span>
                                    </Button>
                                </Box>
                            ) : (
                                <Button
                                    variant="ghost"
                                    onClick={() => handleClose()}
                                >
                                    <span>Close</span>
                                </Button>
                            )}
                        </ModalFooter>
                    ) : (
                        <ModalFooter
                            display="flex"
                            justifyContent="center"
                            mb={4}
                        >
                            <Spinner />
                        </ModalFooter>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}
