import { Box, Table, Tbody, Td, Tr } from '@chakra-ui/react'
import React from 'react'
import { MatchDTO } from '../../../data'

export function Matches({ matches }: { matches: MatchDTO[] }) {
    return (
        <Box>
            {matches.map((match, i) => {
                return (
                    <Box key={match.matchId} mt={2} mb={2} fontSize={14}>
                        <Table size="sm">
                            <Tbody>
                                <Tr>
                                    <Td
                                        width="5rem"
                                        paddingEnd="0"
                                        paddingStart="0"
                                    >
                                        Match {1 + i++}:
                                    </Td>
                                    <Td paddingEnd="0" paddingStart="0">{`${
                                        match.homeTeam ? match.homeTeam : 'TBC'
                                    } vs ${
                                        match.awayTeam ? match.awayTeam : 'TBC'
                                    } @ ${
                                        match.venue ? match.venue : 'TBC'
                                    }`}</Td>
                                    <Td
                                        textAlign="end"
                                        paddingEnd="0"
                                        fontWeight="600"
                                    >
                                        {match.selectedTip}
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>
                )
            })}
        </Box>
    )
}
