import { useMutation } from 'react-query'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { environment } from '../../environments/environment'
import { Season, SeasonConfig } from '../data'
import { useToast } from '@chakra-ui/react'

export function useConfigureSeason() {
    const { oidcUser } = useReactOidc()
    const toast = useToast()

    return useMutation(
        async ({
            config,
            season,
        }: {
            config: SeasonConfig
            season: Season
        }) => {
            return new Promise((resolve, reject) => {
                fetch(`${environment.apiGatewayUrl}/configure-season`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${oidcUser.access_token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        config,
                        season: season.season,
                        sport: season.sport,
                        game: season.game,
                    }),
                })
                    .then(async (resp) => {
                        if (resp.status !== 200) {
                            const fetchedResp: any = await resp.json()
                            toast({
                                title:
                                    'Oh no, something went wrong configuring the season',
                                description:
                                    'Please check the console and contact the dev team. You might need to update the prizing information manually in the database',
                                status: 'error',
                                duration: 9000,
                                isClosable: true,
                            })
                            reject(
                                fetchedResp?.message
                                    ? fetchedResp.message
                                    : 'Couldnt configure season',
                            )
                        } else {
                            toast({
                                title: 'Success',
                                description: 'Season updated',
                                status: 'success',
                                duration: 9000,
                                isClosable: true,
                            })
                        }
                        resolve(resp.status)
                    })
                    .catch(() => {
                        reject('Couldnt configure season')
                    })
            })
        },
    )
}
