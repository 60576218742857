import { useMutation } from 'react-query'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { environment } from '../../environments/environment'
import { useToast } from '@chakra-ui/react'

export function useRemovePublicAssetLocal() {
    const { oidcUser } = useReactOidc()
    const toast = useToast()

    return useMutation(async ({ filename }: { filename: string }) => {
        const form = new FormData()
        form.append('filename', filename)

        return new Promise((resolve, reject) => {
            fetch(`${environment.apiGatewayUrl}/remove-public-asset-local`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${oidcUser.access_token}`,
                },
                body: form,
            })
                .then(async (resp) => {
                    if (resp.status !== 201) {
                        const fetchedResp: any = await resp.json()
                        toast({
                            title:
                                'Oh no, something went wrong removing file..',
                            description:
                                'Please check the console and contact the dev team. You might need to manually put the file into the pubic_assets/ S3 bucket',
                            status: 'error',
                            duration: 9000,
                            isClosable: true,
                        })
                        reject(
                            fetchedResp?.message
                                ? fetchedResp.message
                                : 'Couldnt remove file ',
                        )
                    } else {
                        toast({
                            title: 'Success',
                            description: 'File successfully removed',
                            status: 'success',
                            duration: 9000,
                            isClosable: true,
                        })
                    }
                    resolve(resp.status)
                })
                .catch(() => {
                    reject('Couldnt remove file ')
                })
        })
    })
}
