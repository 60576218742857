import React, { useState } from 'react'
import {
    Box,
    Text,
    Button,
    Image,
    Input,
    Textarea,
    useToast,
} from '@chakra-ui/react'
import { Season, Prize } from '../data'
import { useHistory } from 'react-router-dom'
import { useConfigureSeason } from '../hooks/useConfigureSeason'
import { useUploadPublicAssetLocal } from '../hooks/useUploadPublicAssetLocal'
import { useGetPresignedUrl } from '../hooks/useGetPresignedUrl'
import { FileControl } from '../components/FileControl'
import { environment } from '../../environments/environment'
import { isLocalEnv } from '../helpers/is-local-env'
import { MdPublishedWithChanges } from 'react-icons/md'

const emptyPrize: Prize = {
    title: '',
    content: '',
    image: undefined,
    subtext: undefined,
}

export function ManagePrizing({
    season,
    refetch,
}: {
    season: Season
    refetch: () => Promise<Season[] | undefined>
}) {
    const history = useHistory()
    const [configureSeason] = useConfigureSeason()
    const [getSignedUrl] = useGetPresignedUrl()
    const [uploadFile] = useUploadPublicAssetLocal()
    const toast = useToast()

    const [prizes, setPrizes] = useState(season.config?.prizing || [emptyPrize])
    const onChange = (
        idx: number,
        prop: 'title' | 'subtext' | 'content',
        value: string,
    ) => {
        setPrizes((originalPrizes) => {
            originalPrizes[idx][prop] = value
            return [...originalPrizes]
        })
    }

    const validatePrizes = () => {
        let valid = true
        if (prizes === undefined) return false
        prizes.forEach((p) => {
            if (p.title === '' || p.content === '') valid = false
        })
        return valid
    }

    const onImageSelect = async (file: File, idx: number) => {
        const ext = file.name.split('.').pop()
        const filename = `${season.season}-${
            season.sport
        }-prize-${Date.now()}.${ext}`
        const contentType = file.type

        if (isLocalEnv()) {
            await uploadFile({
                file,
                filename,
            })

            setPrizes((originalPrizes) => {
                originalPrizes[idx]['image'] = filename
                return [...originalPrizes]
            })

            await refetch()
        } else {
            try {
                const response = await getSignedUrl({ filename, contentType })

                if (!response) {
                    throw Error('No response from server')
                }

                const result = await fetch(response.url, {
                    method: 'PUT',
                    body: file,
                })

                if (result.status === 200) {
                    setPrizes((originalPrizes) => {
                        originalPrizes[idx]['image'] = filename
                        return [...originalPrizes]
                    })
                } else {
                    toast({
                        title: 'Oh no, something went wrong uploading file..',
                        description:
                            'Please check the console and contact the dev team. You might need to manually put the file into the pubic_assets/ S3 bucket',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            } catch {
                toast({
                    title:
                        'Oh no, something went wrong generating the presigned URL...',
                    description:
                        'Please check the console and contact the dev team. You might need to manually put the file into the pubic_assets/ S3 bucket',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        }
    }

    const savePrizes = async (prizesToSave: Prize[]) => {
        const config = {
            termsAndConditions: '',
            sendGridId: '',
            ingestionCompId: '',
            ...season.config,
            prizing: prizesToSave,
        }
        const res = await configureSeason({ season, config })

        if (res === 200) {
            await refetch()
            history.push(`/seasons/${season.sport}_${season.season}`)
        }
    }

    return (
        <Box display="flex" justifyContent="center">
            <Box width="700px">
                <Text fontSize="xx-large" mb="5">
                    Configure prizing information
                </Text>

                {prizes.map((p, idx) => (
                    <Box key={idx} mb="10">
                        {idx !== 0 && (
                            <Box height="0.5px" flex="1" background="#A8A8A8" />
                        )}

                        <Box ml="10" mr="10" mt="5">
                            <Text mb="2" mt="6">
                                Title
                            </Text>
                            <Input
                                placeholder="Enter the title of the prize"
                                title="Title"
                                value={p.title}
                                onChange={({ target }) =>
                                    onChange(idx, 'title', target.value)
                                }
                            />

                            <Text mb="2" mt="6">
                                Content
                            </Text>
                            <Textarea
                                placeholder="Enter the information about the prize"
                                value={p.content}
                                onChange={({ target }) =>
                                    onChange(idx, 'content', target.value)
                                }
                            />

                            <Text mb="2" mt="6">
                                Subtext (optional)
                            </Text>
                            <Textarea
                                placeholder="eg: Put your tips in for the round, then watch 7NEWS from 6pm the following Wednesday night to see if you are that week’s finalist."
                                value={p.subtext}
                                onChange={({ target }) =>
                                    onChange(idx, 'subtext', target.value)
                                }
                            />

                            <Box
                                mb="2"
                                mt="8"
                                border="1px #E2E8F0 solid"
                                p="3"
                                borderRadius="5"
                                alignItems="center"
                            >
                                <Box display="flex">
                                    <Text flex="1">
                                        Attach Image (optional)
                                    </Text>
                                    <FileControl
                                        buttonLabel="Upload"
                                        onSelect={(file) =>
                                            onImageSelect(file, idx)
                                        }
                                        accept=".png, .jpg"
                                    />
                                </Box>

                                {!!p.image && (
                                    <Image
                                        src={
                                            isLocalEnv()
                                                ? `${environment.apiGatewayUrl}/public-assets/${p.image}`
                                                : `${environment.frontendUrl}/public-assets/${p.image}`
                                        }
                                        width="150px"
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                ))}

                <Box display="flex" alignItems="center">
                    <Box height="0.5px" flex="1" background="#A8A8A8" />
                    <Button
                        variant="outline"
                        mr="2"
                        ml="2"
                        onClick={() =>
                            setPrizes((p) => [...p, { title: '', content: '' }])
                        }
                    >
                        Add Prize
                    </Button>
                    <Button
                        variant="outline"
                        mr="2"
                        onClick={() => setPrizes((p) => [...p.slice(0, -1)])}
                    >
                        Remove Prize
                    </Button>
                    <Box height="0.5px" flex="1" background="#A8A8A8" />
                </Box>

                <Box justifyContent="right" display="flex" mb="10" mt="5">
                    <Button
                        variant="outline"
                        onClick={() => history.goBack()}
                        colorScheme="blue"
                        mr="2"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => savePrizes(prizes)}
                        colorScheme="blue"
                        disabled={!validatePrizes()}
                        leftIcon={<MdPublishedWithChanges />}
                    >
                        Publish Changes
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
