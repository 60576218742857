import React, { useState } from 'react'
import {
    Button,
    Box,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    IconButton,
    Select,
    Input,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    ButtonGroup,
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { ManageSeasonBaseProps } from './ManageSeason'
import { StyledModal } from '../components/StyledModal'
import { EditIcon } from '@chakra-ui/icons'
import { MajorPrizeWinner, RoundWinner } from '../data'
import { usePublishSeason } from '../hooks/usePublishSeason'
import { AddIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { MdPublishedWithChanges } from 'react-icons/md'

interface EditingMajorPrizeWinner extends MajorPrizeWinner {
    index: number
}

interface EditingRoundWinner extends RoundWinner {
    index: number
}

export function ManageWinners({ season, refetch }: ManageSeasonBaseProps) {
    const [majorWinner, setMajorWinner] = useState<
        EditingMajorPrizeWinner | undefined
    >()
    const [roundWinner, setRoundWinner] = useState<
        EditingRoundWinner | undefined
    >()
    const [maxRounds, setMaxRounds] = useState(
        season.winners?.maxRounds || season.totalRounds,
    )
    const [hideWinners, setHideWinners] = useState(
        season.winners?.hideWinners || false,
    )

    const [majorPrizeWinners, setMajorPrizeWinners] = useState<
        MajorPrizeWinner[]
    >(season.winners?.majorPrizeWinners || [])
    const [roundWinners, setRoundWinners] = useState<RoundWinner[]>(
        season.winners?.roundWinners || [],
    )

    const [publishSeason] = usePublishSeason()
    const history = useHistory()

    const publishWinners = async () => {
        const res = await publishSeason({
            season: {
                ...season,
                winners: {
                    ...season.winners,
                    majorPrizeWinners,
                    roundWinners,
                    maxRounds,
                    hideWinners,
                },
            },
        })

        if (res === 200) {
            await refetch()
            history.push(`/seasons/${season.sport}_${season.season}`)
        }
    }

    return (
        <Box display="flex" alignItems="center" flexDir="column">
            <Text fontSize="xx-large" mt="4">
                Prize winners for {season.sport} ({season.season})
            </Text>

            <Box width="700px" mt="4">
                <Box display="flex">
                    <Box flex="1">
                        <Text fontSize="x-large" mt="4" flex="1">
                            Max Rounds
                        </Text>
                        <NumberInput
                            value={maxRounds}
                            onChange={(e) => {
                                const num = Number(e)
                                if (!isNaN(num)) setMaxRounds(num)
                            }}
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </Box>
                    <Box flex="1" />
                    <Box flex="1">
                        <Text fontSize="x-large" mt="4" flex="1">
                            Display Winners
                        </Text>
                        <ButtonGroup isAttached>
                            <Button
                                width="105px"
                                variant={hideWinners ? 'outline' : 'solid'}
                                colorScheme="blue"
                                onClick={() => setHideWinners(false)}
                                leftIcon={<ViewIcon />}
                            >
                                Show
                            </Button>
                            <Button
                                width="105px"
                                variant={!hideWinners ? 'outline' : 'solid'}
                                colorScheme="blue"
                                onClick={() => setHideWinners(true)}
                                leftIcon={<ViewOffIcon />}
                            >
                                Hide
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Box>

                <Text fontSize="x-large" mt="8" flex="1">
                    Major Winners
                </Text>
                <TableContainer
                    borderColor="blackAlpha.250"
                    borderWidth="thin"
                    padding="4"
                    borderRadius="12"
                    mt="4"
                    mb="4"
                >
                    <Table variant="striped" colorScheme="gray">
                        <Thead>
                            <Tr>
                                <Th>Prize Name</Th>
                                <Th>Winner</Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {majorPrizeWinners.map((w, index) => (
                                <Tr key={w.prize + w.winner + index}>
                                    <Td>{w.prize}</Td>
                                    <Td>{w.winner}</Td>
                                    <Td>
                                        <IconButton
                                            size="sm"
                                            variant="ghost"
                                            icon={<EditIcon />}
                                            aria-label="edit"
                                            colorScheme="blue"
                                            onClick={() => {
                                                setMajorWinner({ ...w, index })
                                            }}
                                        />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>

                <Button
                    leftIcon={<AddIcon />}
                    variant="outline"
                    colorScheme="blue"
                    onClick={() =>
                        setMajorWinner({
                            prize: '',
                            winner: '',
                            index: majorPrizeWinners.length || 0,
                        })
                    }
                >
                    Add major winner
                </Button>

                <Text fontSize="x-large" mt="8" flex="1">
                    Round Winners
                </Text>
                <TableContainer
                    borderColor="blackAlpha.250"
                    borderWidth="thin"
                    padding="4"
                    borderRadius="12"
                    mt="4"
                    mb="4"
                >
                    <Table variant="striped" colorScheme="gray">
                        <Thead>
                            <Tr>
                                <Th>Round</Th>
                                <Th>Type</Th>
                                <Th>Name</Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {roundWinners.map((w, index) => (
                                <Tr key={w.round + w.type + index}>
                                    <Td>{w.round}</Td>
                                    <Td>{w.type}</Td>
                                    <Td>{w.name}</Td>
                                    <Td>
                                        <IconButton
                                            size="sm"
                                            variant="ghost"
                                            icon={<EditIcon />}
                                            aria-label="edit"
                                            colorScheme="blue"
                                            onClick={() => {
                                                setRoundWinner({ ...w, index })
                                            }}
                                        />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
                <Button
                    leftIcon={<AddIcon />}
                    variant="outline"
                    colorScheme="blue"
                    onClick={() =>
                        setRoundWinner({
                            round: season.currentRound,
                            name: '',
                            type: 'prize',
                            index: roundWinners.length || 0,
                        })
                    }
                >
                    Add round winner
                </Button>

                <Box display="flex" justifyContent="end" mt="4">
                    <Button
                        variant="outline"
                        colorScheme="blue"
                        onClick={() => history.go(-1)}
                    >
                        Cancel
                    </Button>
                    <Button
                        ml="4"
                        colorScheme="blue"
                        onClick={publishWinners}
                        leftIcon={<MdPublishedWithChanges />}
                    >
                        Publish Changes
                    </Button>
                </Box>
            </Box>

            <StyledModal
                onClose={() => setMajorWinner(undefined)}
                isOpen={!!majorWinner}
                size="lg"
            >
                {majorWinner && (
                    <Box>
                        <Text fontSize="large">Edit Major Winner</Text>

                        <Text mt="4">Prize Name</Text>
                        <Input
                            value={majorWinner.prize}
                            onChange={(e) =>
                                setMajorWinner({
                                    winner: majorWinner.winner || '',
                                    index: majorWinner.index || 0,
                                    prize: e.target.value,
                                })
                            }
                        />

                        <Text mt="4">Winner</Text>
                        <Input
                            value={majorWinner.winner}
                            onChange={(e) =>
                                setMajorWinner({
                                    prize: majorWinner.prize || '',
                                    index: majorWinner.index || 0,
                                    winner: e.target.value,
                                })
                            }
                        />

                        <Box mt="4" mb="4">
                            <Button
                                variant="outline"
                                onClick={() => setMajorWinner(undefined)}
                            >
                                Cancel
                            </Button>

                            <Button
                                ml="4"
                                colorScheme="blue"
                                onClick={() => {
                                    setMajorPrizeWinners((m) => {
                                        m[majorWinner.index] = {
                                            prize: majorWinner.prize,
                                            winner: majorWinner.winner,
                                        }
                                        return m
                                    })
                                    setMajorWinner(undefined)
                                }}
                            >
                                Save
                            </Button>

                            {majorWinner.index < majorPrizeWinners.length && (
                                <Button
                                    ml="4"
                                    colorScheme="red"
                                    onClick={() => {
                                        setMajorPrizeWinners((m) => {
                                            m.splice(majorWinner.index, 1)
                                            return m
                                        })
                                        setMajorWinner(undefined)
                                    }}
                                >
                                    Delete
                                </Button>
                            )}
                        </Box>
                    </Box>
                )}
            </StyledModal>

            <StyledModal
                onClose={() => setRoundWinner(undefined)}
                isOpen={!!roundWinner}
                size="lg"
            >
                {roundWinner && (
                    <Box>
                        <Text fontSize="large">Edit Round Winner</Text>

                        <Text mt="4">Winner</Text>
                        <Input
                            value={roundWinner.name}
                            onChange={(e) =>
                                setRoundWinner({
                                    type: roundWinner.type,
                                    round: roundWinner.round,
                                    index: roundWinner.index,
                                    name: e.target.value,
                                })
                            }
                        />

                        <Text mt="4">Round</Text>
                        <Input
                            value={roundWinner.round}
                            onChange={(e) => {
                                const round = Number(e.target.value)
                                if (!isNaN(round))
                                    setRoundWinner({
                                        type: roundWinner.type,
                                        name: roundWinner.name,
                                        index: roundWinner.index,
                                        round,
                                    })
                            }}
                        />

                        <Text mt="4">Type</Text>
                        <Select
                            value={roundWinner.type}
                            onChange={(e) =>
                                setRoundWinner({
                                    type: e.target.value as
                                        | 'prize'
                                        | 'finalist',
                                    name: roundWinner.name,
                                    round: roundWinner.round,
                                    index: roundWinner.index,
                                })
                            }
                        >
                            <option value="prize">Prize</option>
                            <option value="finalist">Finalist</option>
                        </Select>

                        <Box mt="4" mb="4">
                            <Button
                                variant="outline"
                                onClick={() => {
                                    setRoundWinner(undefined)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                ml="4"
                                colorScheme="blue"
                                onClick={() => {
                                    if (roundWinner) {
                                        setRoundWinners((r) => {
                                            r[roundWinner.index] = {
                                                type: roundWinner.type,
                                                name: roundWinner.name,
                                                round: roundWinner.round,
                                            }
                                            return r
                                        })
                                        setRoundWinner(undefined)
                                    }
                                }}
                            >
                                Save
                            </Button>

                            {roundWinner.index < roundWinners.length && (
                                <Button
                                    ml="4"
                                    colorScheme="red"
                                    onClick={() => {
                                        if (roundWinner) {
                                            setRoundWinners((r) => {
                                                r.splice(roundWinner.index, 1)
                                                return r
                                            })
                                            setRoundWinner(undefined)
                                        }
                                    }}
                                >
                                    Delete
                                </Button>
                            )}
                        </Box>
                    </Box>
                )}
            </StyledModal>
        </Box>
    )
}
