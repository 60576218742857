import { useMutation } from 'react-query'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { environment } from '../../environments/environment'
import { MatchDTO } from '../data'
import { useToast } from '@chakra-ui/react'

export enum Action {
    ToggleLock = 'toggleLock',
    SetFeatured = 'setFeatured',
    SwapTeams = 'swapTeams',
}

type EditMatchProps = {
    action: Action
    match: MatchDTO
}

export function useEditMatch() {
    const { oidcUser } = useReactOidc()
    const toast = useToast()

    return useMutation(async ({ action, match }: EditMatchProps) => {
        return new Promise((resolve, reject) => {
            fetch(`${environment.apiGatewayUrl}/edit-match`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${oidcUser.access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action,
                    matchId: match.matchId,
                    sport: match.sport,
                    season: match.season,
                }),
            })
                .then(async (resp) => {
                    if (resp.status !== 200) {
                        const fetchedResp: any = await resp.json()
                        toast({
                            title:
                                'Oh no, something went wrong editing match..',
                            description:
                                'Please check the console and contact the dev team.',
                            status: 'error',
                            duration: 9000,
                            isClosable: true,
                        })
                        reject(
                            fetchedResp?.message
                                ? fetchedResp.message
                                : 'Couldnt edit match',
                        )
                    }
                    resolve(resp.json())
                })
                .catch(() => {
                    reject('Couldnt edit match')
                })
        })
    })
}
