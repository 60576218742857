import React, { useEffect, useState } from 'react'
import { Tabs, Tab, TabList, useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

export function Menu({ hasFullAccess }: { hasFullAccess: boolean }) {
    const history = useHistory()
    const toast = useToast()

    useEffect(() => {
        if (history.location.pathname.startsWith('/seasons')) {
            if (!hasFullAccess) return
            setTabIndex(0)
        } else if (history.location.pathname.startsWith('/search')) {
            setTabIndex(1)
        } else if (history.location.pathname.startsWith('/settings')) {
            if (!hasFullAccess) return
            setTabIndex(2)
        } else {
            setTabIndex(1)
        }
    }, [history.location.pathname, hasFullAccess])

    const [tabIndex, setTabIndex] = useState(1)

    const handleTabsChange = (index: number) => {
        if (!hasFullAccess) return

        setTabIndex(index)
    }

    function fullAccessRedirect(path: string) {
        hasFullAccess
            ? history.push(path)
            : toast({
                  title: 'Invalid access',
                  description:
                      "You don't have access rights for this tab. If you think you should, please contact the dev team.",
                  status: 'warning',
                  duration: 5000,
                  isClosable: true,
              })
    }

    return (
        <Tabs index={tabIndex} onChange={handleTabsChange} mb={4}>
            <TabList>
                <Tab
                    onClick={() => fullAccessRedirect('/seasons')}
                    opacity={hasFullAccess ? '1' : '0.5'}
                >
                    Manage Seasons
                </Tab>
                <Tab onClick={() => history.push('/search')}>User Actions</Tab>
                <Tab
                    onClick={() => fullAccessRedirect('/settings')}
                    opacity={hasFullAccess ? '1' : '0.5'}
                >
                    Settings
                </Tab>
            </TabList>
        </Tabs>
    )
}
