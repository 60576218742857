import { Box, Button, Flex, Input, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import React, { useState } from 'react'
import { MdCancel, MdDelete, MdEdit, MdUpload } from 'react-icons/md'
import { SportName } from '../data'
import { StyledModal } from './StyledModal'

export interface BannerMessageComponents {
    id: string
    title: string
    body: string
    sport: SportName | ''
    date: string
}

const Dropdown = styled('select')(() => ({
    color: 'white',
    background: 'none',
    borderRadius: '2px',
    borderWidth: '1px',
    height: '32px',
    width: '100%',
}))

export function BannerMessageWidget({
    message,
    handleSaveMessage,
    handleDeleteMessage,
    loading,
}: {
    message: BannerMessageComponents
    handleSaveMessage: (sponsor: BannerMessageComponents) => {}
    handleDeleteMessage: (sponsor: BannerMessageComponents) => {}
    loading: boolean
}) {
    const { id, title, body, sport, date } = message
    const [editable, setEditable] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [newTitle, setNewTitle] = useState(title || '')
    const [newBody, setNewBody] = useState(body || '')
    const [newSport, setNewSport] = useState(sport || '')
    let color = ''
    switch (newSport) {
        case 'AFL':
            color = '#265cd9'
            break
        case 'AFLW':
            color = '#F16C52'
            break
        case 'Cricket':
            color = '#c75300'
            break
        case 'WAFL':
            color = '#007A85'
            break
        default:
            color = '#265cd9'
            break
    }

    function handleClickEdit() {
        setNewTitle(title || '')
        setNewBody(body || '')
        setNewSport(sport || '')

        setEditable(!editable)
    }

    function handleClickDelete() {
        setShowDeleteModal(true)
    }

    async function handleConfirmDelete() {
        setShowDeleteModal(false)
        setEditable(false)
        await handleDeleteMessage(message)
    }

    async function handleClickSave() {
        await handleSaveMessage({
            id,
            title: newTitle,
            body: newBody,
            sport: newSport as SportName,
            date,
        })
        setEditable(false)
    }

    // to do, fix weird currently selected option on top of list thing

    return (
        <>
            <Flex
                direction="row"
                justifyContent="space-between"
                padding={4}
                border={`1px #65707d77 solid`}
                background={editable ? color : 'none'}
                transition="0.5s"
                borderRadius={8}
                maxWidth="1000"
                gap={4}
                opacity={loading ? '0.6' : '1'}
            >
                {!title && !body && !editable ? (
                    <Text margin={'auto'}>
                        <i>No message</i>
                    </Text>
                ) : (
                    <>
                        <Flex
                            flexDirection="column"
                            gap={2}
                            paddingRight={4}
                            color={editable ? 'white' : 'none'}
                            borderRight={`1px solid ${
                                editable ? '#ffffff77' : '#65707d77'
                            }`}
                        >
                            <div>
                                <Text opacity={0.7} fontSize="xs">
                                    Sport
                                </Text>
                                {editable ? (
                                    <Dropdown
                                        placeholder={sport}
                                        value={newSport}
                                        onChange={(e) => {
                                            setNewSport(
                                                e.target.value === 'No sport'
                                                    ? ''
                                                    : (e.target
                                                          .value as SportName),
                                            )
                                        }}
                                    >
                                        <option key="No sport" value="No sport">
                                            {'No sport'}
                                        </option>
                                        <option key="AFL" value="AFL">
                                            AFL
                                        </option>
                                        <option key="AFLW" value="AFLW">
                                            AFLW
                                        </option>
                                        <option key="Cricket" value="Cricket">
                                            Cricket
                                        </option>
                                        <option key="WAFL" value="WAFL">
                                            WAFL
                                        </option>
                                    </Dropdown>
                                ) : (
                                    <Text
                                        lineHeight={1.2}
                                        wordBreak="break-word"
                                        opacity={sport ? '1' : '0.5'}
                                    >
                                        {`${sport === '' ? 'No Sport' : sport}`}
                                    </Text>
                                )}
                            </div>
                            <div>
                                <Text opacity={0.7} fontSize="xs">
                                    Date Updated
                                </Text>
                                <Text
                                    lineHeight={1.2}
                                    wordBreak="break-word"
                                    opacity={date ? '1' : '0.5'}
                                >
                                    {`${date ?? 'No date'}`}
                                </Text>
                            </div>
                        </Flex>
                        <Flex
                            flexDirection="column"
                            gap={2}
                            flex="1"
                            // justifyContent="center"
                            color={editable ? 'white' : 'none'}
                        >
                            <>
                                <div>
                                    <Text opacity={0.7} fontSize="xs">
                                        Title
                                    </Text>
                                    {editable ? (
                                        <Input
                                            placeholder={title}
                                            size="sm"
                                            value={newTitle}
                                            onChange={(e) =>
                                                setNewTitle(e.target.value)
                                            }
                                        />
                                    ) : (
                                        <Text
                                            lineHeight={1.2}
                                            wordBreak="break-word"
                                        >
                                            {`${title ?? '[No Title]'}`}
                                        </Text>
                                    )}
                                </div>
                                <div>
                                    <Text opacity={0.7} fontSize="xs">
                                        Body
                                    </Text>
                                    {editable ? (
                                        <Input
                                            placeholder={body}
                                            size="sm"
                                            value={newBody}
                                            onChange={(e) =>
                                                setNewBody(e.target.value)
                                            }
                                        />
                                    ) : (
                                        <Text
                                            lineHeight={1.2}
                                            wordBreak="break-word"
                                        >
                                            {`${body ?? '[No Body]'}`}
                                        </Text>
                                    )}
                                </div>
                            </>
                        </Flex>
                    </>
                )}
                <Flex flexDirection="column" gap="2" justifyContent="center">
                    <Button
                        onClick={() => handleClickEdit()}
                        leftIcon={editable ? <MdCancel /> : <MdEdit />}
                        disabled={loading}
                    >
                        {editable ? 'Cancel' : 'Edit'}
                    </Button>
                    {editable && (
                        <Flex flexDirection="row" gap="2">
                            <Button
                                onClick={() => handleClickDelete()}
                                colorScheme="red"
                                leftIcon={<MdDelete />}
                                disabled={loading}
                            >
                                Delete
                            </Button>

                            <Button
                                onClick={() => handleClickSave()}
                                colorScheme="blue"
                                leftIcon={<MdUpload />}
                                disabled={loading}
                            >
                                Publish
                            </Button>
                        </Flex>
                    )}
                </Flex>
            </Flex>
            <StyledModal
                size="lg"
                onClose={() => setShowDeleteModal(false)}
                isOpen={showDeleteModal}
                footer={
                    <Box>
                        <Button
                            onClick={() => setShowDeleteModal(false)}
                            colorScheme="white"
                            variant="outline"
                        >
                            Cancel
                        </Button>
                        <Button
                            ml="4"
                            colorScheme="red"
                            onClick={handleConfirmDelete}
                            leftIcon={<MdDelete />}
                        >
                            Delete
                        </Button>
                    </Box>
                }
            >
                <Box marginTop={4}>
                    <Text fontSize={'large'}>
                        Delete message? It will not show for any users.
                    </Text>
                </Box>
            </StyledModal>
        </>
    )
}
