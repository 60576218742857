import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { SearchForm } from './SearchForm'
import { SettingsForm } from './Settings'
import { SeasonForm, SeasonPageName } from './ManageSeason/SeasonForm'
import { NewSeasonForm } from './ManageSeason/NewSeasonForm'

export function Routes({
    hasFullAccess,
}: {
    hasFullAccess: boolean
}): JSX.Element {
    if (hasFullAccess)
        return (
            <Switch>
                <Route path="/search" component={SearchForm} />
                <Route path="/settings" component={SettingsForm} />
                <Route path="/seasons/newseason" component={NewSeasonForm} />

                <Route
                    path="/seasons/:seasonId/:page"
                    render={({ match }) => (
                        <SeasonForm
                            seasonId={match.params.seasonId}
                            page={match.params.page as SeasonPageName}
                        />
                    )}
                />

                <Route
                    path="/seasons/:seasonId"
                    render={({ match }) => (
                        <SeasonForm seasonId={match.params.seasonId} />
                    )}
                />
                <Route path="/seasons" component={SeasonForm} />

                <Route path="/">
                    <Redirect to="/search" />
                </Route>
            </Switch>
        )

    return (
        <Switch>
            <Route path="/search" component={SearchForm} />

            <Route path="/">
                <Redirect to="/search" />
            </Route>
        </Switch>
    )
}
