import { useMutation } from 'react-query'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { environment } from '../../environments/environment'
import { Season, NewRound } from '../data'
import { useToast } from '@chakra-ui/react'

export function useAddRounds() {
    const { oidcUser } = useReactOidc()
    const toast = useToast()

    return useMutation(
        async ({ rounds, season }: { rounds: NewRound[]; season: Season }) => {
            return new Promise((resolve, reject) => {
                fetch(`${environment.apiGatewayUrl}/add-rounds`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${oidcUser.access_token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        rounds: rounds.map((r) => ({
                            start: r.start,
                            end: r.end,
                        })),
                        season: season.season,
                        sport: season.sport,
                        game: season.game,
                    }),
                })
                    .then(async (resp) => {
                        if (resp.status !== 200) {
                            const fetchedResp: any = await resp.json()
                            toast({
                                title:
                                    'Oh no, something went wrong adding rounds',
                                description:
                                    'Please check the console and contact the dev team.',
                                status: 'error',
                                duration: 9000,
                                isClosable: true,
                            })
                            reject(
                                fetchedResp?.message
                                    ? fetchedResp.message
                                    : 'Couldnt add rounds to season',
                            )
                        } else {
                            toast({
                                title: 'Success',
                                description: 'Rounds updated',
                                status: 'success',
                                duration: 9000,
                                isClosable: true,
                            })
                        }
                        resolve(resp.status)
                    })
                    .catch(() => {
                        reject('Couldnt add rounds to season')
                    })
            })
        },
    )
}
