import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'
import { MarginDTO, MatchDTO } from '../../../data'
import { AdminErrorBoundary } from '../../../ErrorBoundary'

export function Margin({
    filteredMargins,
    matches,
}: {
    filteredMargins: MarginDTO[]
    matches: Record<string, MatchDTO>
}) {
    const sortedMargins = filteredMargins.sort(
        (a, b) => a.roundNumber - b.roundNumber,
    )

    return (
        <AdminErrorBoundary>
            <Table size="sm">
                <Thead>
                    <Tr>
                        <Th>Round</Th>
                        <Th>Game</Th>
                        <Th>Margin</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {sortedMargins.map((margin) => {
                        const match = matches
                            ? matches[margin.matchId]
                            : undefined

                        return (
                            <Tr key={margin.matchId}>
                                <Td>{margin.roundNumber}</Td>
                                <Td>
                                    {match &&
                                        `${match.homeTeam} vs ${match.awayTeam} @ ${match.venue} `}
                                </Td>
                                <Td fontWeight="600">{margin.tippedMargin}</Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </AdminErrorBoundary>
    )
}
