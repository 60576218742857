import React from 'react'
import { Flex } from '@chakra-ui/react'
import { AppVersionSection } from './sections/AppVersionSection'
import { Sponsors } from './sections/SponsorsSection'
import { OfficialPartnerUpload } from './sections/OfficialPartnerUpload'
import { BannerMessageSection } from './sections/BannerMessageSection'

export function SettingsForm() {
    return (
        <Flex direction="column" px={'10'} marginBottom={48} gap={10}>
            <BannerMessageSection />
            <OfficialPartnerUpload />
            <Sponsors />
            <AppVersionSection />
        </Flex>
    )
}
