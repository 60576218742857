import {
    Alert,
    AlertDescription,
    AlertIcon,
    CloseButton,
    Fade,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

export type NotificationTypes = 'info' | 'warning' | 'success' | 'error'

export interface INotification {
    type: NotificationTypes
    message: string
    hideTimeout: number
    onNotificationClose?: () => void
}

export function Notification({
    type,
    message,
    hideTimeout,
    onNotificationClose,
}: INotification) {
    const [isShow, setShow] = useState(true)

    useEffect(() => {
        const hideNotification = setTimeout(() => {
            setShow(false)
            onNotificationClose()
        }, hideTimeout)
        return () => {
            clearTimeout(hideNotification)
        }
    })

    return (
        <Fade in={isShow}>
            <Alert
                status={type}
                position="fixed"
                right="8px"
                top="8px"
                width="22rem"
                zIndex="99"
            >
                <AlertIcon />
                <AlertDescription width="16.5rem">{message}</AlertDescription>
                <CloseButton
                    onClick={onNotificationClose}
                    position="absolute"
                    right="8px"
                    top="8px"
                />
            </Alert>
        </Fade>
    )
}
